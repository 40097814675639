import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {VgCoreModule} from 'videogular2/core';
import {VgControlsModule} from 'videogular2/controls';
import {VgOverlayPlayModule} from 'videogular2/overlay-play';
import {VgBufferingModule} from 'videogular2/buffering';
import { AuthService } from './service/auth.service';
import { InitialService } from './service/initial/initial.service';
import { NavbarComponent } from './navbar/navbar.component';
import { LoedVideoComponent } from './loed-video/loed-video.component';
import { FooterComponent } from './footer/footer.component';
import { ViewVideoComponent } from './view-video/view-video.component';
import { VgStreamingModule } from 'videogular2/streaming';
import { from } from 'rxjs';

export function init(appLoadService: InitialService) {
  return () => appLoadService.init();
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoedVideoComponent,
    FooterComponent,
    ViewVideoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    HttpClientModule,
    VgStreamingModule
  ],
  providers: [
    AuthService,
    InitialService,
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      deps: [InitialService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
