import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token: any;
  private public: any;
  private data: any;
  private vdo: any;
  subject = new Subject<any>();
  constructor(private http: HttpClient) { }

  private loadTokenPublic(id) {
    const gettoken = this.http.get(environment.environment.API_BACKEND + '/api/v2/token/public');
    gettoken.subscribe(d => {
      this.public = d;
      this.token = this.public.data.token;
      this.checkIdVideo(id).subscribe(c => {
        this.data = c;
        if ( this.data.status === 'OK') {
          this.viewVideo(this.data.data.id).subscribe(v => {
            this.vdo = v;
            this.sendMessage(v);
          });
        } else {
          console.log(this.data);
        }
      }, e => {
        // console.log(e);
      });
    });
  }

  sendMessage(message: any) {
    this.subject.next(message);
  }

  clearMessages() {
      this.subject.next();
  }

  getMessage(): Observable<any> {
      return this.subject.asObservable();
  }

  viewVideo(id) {
    return this.http.get(environment.environment.API_BACKEND + '/api/v2/getContent/' + id, {headers: this.setHttpHeaders()});
  }

  setHttpHeaders() {
    const headers = new HttpHeaders({Authorization: 'Token ' + this.token});
    return headers;
  }

  checkIdVideo(id) {
   return this.http.get(environment.environment.API_BACKEND + '/api/v2/token/public/' + id);
  }

  viweLoadVideo(id) {
    this.loadTokenPublic(id);
  }

}
