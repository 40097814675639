import { Component, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import { AuthService } from './service/auth.service';
import {VgAPI, VgFullscreenAPI} from 'videogular2/core';
import { Subscription, Observable, from } from 'rxjs/';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'single-media-player';
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    public ref: ElementRef,
    public API: VgAPI,
    public fsAPI: VgFullscreenAPI
    ) {
    }
}
