import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { VgAPI, VgFullscreenAPI } from 'videogular2/core';

@Component({
  selector: 'app-view-video',
  templateUrl: './view-video.component.html',
  styleUrls: ['./view-video.component.scss']
})
export class ViewVideoComponent implements OnInit, OnDestroy {
  data: any;
  ifLoed = false;
  ifLoedNOId = false;
  private id: string;
  dataSuccess: any;
  subViewVideo: Subscription;
  elem: HTMLElement;
  preload: string = 'auto';
  vgFor: string;
  target: any;
  isFullscreen: boolean = false;
  subscriptions: Subscription[] = [];
  api: VgAPI;
  vid: any;
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    public ref: ElementRef,
    public API: VgAPI,
    public fsAPI: VgFullscreenAPI
    ) {
      this.elem = ref.nativeElement;
    
    }

  ngOnInit() {
    this.subViewVideo = this.route.params.subscribe(params => {
      if (params.id) {
        this.ifLoed = true;
        this.id = params.id; // (+) converts string 'id' to a number
        this.authService.viweLoadVideo(this.id);
      } else {
        this.ifLoedNOId = true;
        console.log('!!!!');
      }
   });
  }

  onPlayerReady1() {
    this.target = this.API.getMediaById(this.vgFor);
}

  onPlayerReady(e) {
  }

  ngOnDestroy() {
    this.subViewVideo.unsubscribe();
  }

  modelChanged(newObj) {
    console.log(newObj);
}

}
