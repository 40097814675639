import { Component, OnInit, Input } from '@angular/core';
import { AuthService} from '../service/auth.service';
import * as dayjs from 'dayjs';
@Component({
  selector: 'app-loed-video',
  templateUrl: './loed-video.component.html',
  styleUrls: ['./loed-video.component.scss']
})
export class LoedVideoComponent implements OnInit {
  // vdo = JSON.parse(localStorage.getItem('mp4'));
  vdo = '';
  data: any;
  resutData = {
    title: String,
    createdAt: String,
    author: String,
    setFormat: String,
    description: String
  };
  ifDetail = false;
  constructor(public auth: AuthService) { }
  ngOnInit() {
    this.auth.getMessage().subscribe(m => {
      this.data = m;
      this.data.setFormat = dayjs(this.data.createdAt).format( 'D ' + this.setMonthTH(dayjs(this.data.createdAt).format('M')) + ' YYYY' );
      this.resutData = this.data;
      // console.log(this.data);
      this.vdo = this.data.videos.m3u8.replace('http://v', 'https://v');
      // console.log(this.vdo)
    });
  }

  setMonthTH(m) {
    const month = ['ม.ค.', 'ก.พ.', 'ม.ค.', 'ม.ย.', 'พ.ค.', 'ม.ย.',
                   'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];
    return month[ parseInt(m) - 1];
  }

  detail() {
    if (this.ifDetail) {
      this.ifDetail = false;
    } else {
      this.ifDetail = true;
    }
  }

}
